import type { IUserInfoInitial } from '@/modules/shared/interfaces/UserInfo'

const parseUserInfo = (data: IUserInfoInitial) => {
  return {
    allowedDomains: data.client_data.allowed_domains,
    maxMailboxes: data.client_data.max_mailboxes,
    maxUsers: data.client_data.max_users,
    maxAlerts: data.client_data.max_alerts,
    emailProvider: data.client_data.provider,
    email: data.email,
    isAdmin: data.role,
    name: data.full_name,
    roles: data.role
  }
}
export default parseUserInfo
