<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'
// import timezones from 'timezones-list'

import type { IMailboxes } from '@/modules/settings/interfaces/Mailboxes'
import { DotsHorizontalIcon } from '@heroicons/vue/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import clientsApi from '@/api/clients-api'
import { BUSINESSHOURS } from '@/api/url'
import ButtonR from '@/modules/shared/components/ButtonRemove.vue'
import MODALS from '@/modules/shared/utils/modals_enum'
import useModal from '@/modules/shared/composable/useModal'
import { setDates } from '@/modules/settings/utils/helpers/dates'
import useTable from '@/modules/settings/composables/useTable'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import { useMailboxesStore } from '@/modules/settings/stores/mailboxes'
import { weekdaysFormat } from '@/modules/settings/utils/parser/bhs'
import useCompanyBusinessHours from '@/modules/settings/composables/useCompanyBusinessHours'

const { selectedItems, selectItem } = useTable()
const { businessHours } = useCompanyBusinessHours()

const { showModalMailboxBusinessHours, removeModal } = useModal()
const { removeData } = useMailboxes()
const storeMailboxes = useMailboxesStore()

const emailsRef = ref([])
const tz = ref()
defineProps({
  mailboxes: {
    type: Object as PropType<IMailboxes[]>,
    required: true
  }
})

const findTimezone = (timezone:string) => {
  const a = Intl.supportedValuesOf('timeZone').find((t) => t.replace('_', ' ') === timezone)
  if (a) {
    tz.value = a.replace('_', ' ')
    // tz.value.utc = a.utc
  }
}
const hoverTool2 = async (ev: { clientX: number; clientY: number }, mailbox:IMailboxes, index:number) => {
  mailbox.bhs.hover = false;
  (emailsRef.value[index] as HTMLDivElement).style.top = '8px'
}

const hoverTool = async (ev: { clientY: number }, mailbox:IMailboxes, index:number) => {
  if (['Custom', 'Company'].includes(mailbox.bhsStatus)) {
    if (![...mailbox.bhs.businessHours].length) {
      if (mailbox.bhsStatus === 'Company') {
        if (businessHours.value.businessHours.length) (emailsRef.value[index] as HTMLDivElement).style.left = `-${28}px`
        else (emailsRef.value[index] as HTMLDivElement).style.left = `-${99}px`
        storeMailboxes.setMailboxBusinessHours(mailbox.email, businessHours.value)
      } else {
        (emailsRef.value[index] as HTMLDivElement).style.left = `-${35}px`
        const { data } = await clientsApi.get(`${BUSINESSHOURS}${mailbox.email}`)
        storeMailboxes.setMailboxBusinessHours(mailbox.email, weekdaysFormat(data))
      }
      findTimezone(mailbox.bhs.timezone)
    }

    setTimeout(() => {
      const totalHeight = (emailsRef.value[index] as HTMLDivElement).clientHeight
      if (window.innerHeight - ev.clientY <= totalHeight + 30) (emailsRef.value[index] as HTMLDivElement).style.top = `-${totalHeight + 28}px`
    }, 100)
    mailbox.bhs.hover = true
  }
}
</script>

<template>
  <tbody class="divide-y divide-gray-200 ">
    <tr v-for="(mailbox, index) in mailboxes" :key="mailbox.email"
        :class="[selectedItems.includes(mailbox.email) && 'bg-gray-50']" class="h-18">
      <td class="relative px-7 sm:w-12 sm:px-6">
        <input
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-0 focus:ring-offset-0"
          :checked="selectedItems.includes(mailbox.email)"
          @change="selectItem($event, mailbox.email)"
        />
      </td>
      <td class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
        {{ mailbox.email }}
        <br>
        <p class="text-gray-500 font-normal">{{ mailbox.name }}</p>
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div
          @mouseover="(e) => hoverTool(e, mailbox, index)"
          @mouseleave="(e) => hoverTool2(e, mailbox, index)"

          :class="{'hover:text-gray-600 cursor-pointer': mailbox.bhsStatus !== 'Ignore' }"
          class="flex items-center relative w-max"
        >
          {{ mailbox.bhsStatus }}
        </div>
        <div class="relative">
          <div
          ref="emailsRef"
          v-show="mailbox.bhs.hover"
          class="bg-white text-white font-medium text-xs transition-opacity opacity-1 p-3 absolute duration-500 ease-in-out  rounded-lg z-10 border border-gray-100 shadow-lg top-2"
          >
            <template v-if="mailbox.bhs.businessHours.length > 0">
              <p class="text-gray-900 text-xs font-medium">{{ tz }}</p>
              <!-- <p class="text-gray-500 text-xs font-normal">GMT {{ tz.utc }}</p> -->
              <div class="mt-2">
                <div
                  v-for="day in mailbox.bhs.businessHours"
                  :key="day.id"
                  class="mt-2 flex"
                >
                  <span :class="[day.status ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-500', 'flex justify-center items-center rounded-3xl text-[10px] w-4 h-4 font-medium leading-4']">
                    {{ day.name }}
                  </span>
                  <span class="text-gray-700 text-xs font-normal leading-4 ml-1.5">{{ `${day.start_time} - ${day.end_time}` }}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="text-gray-900 text-xs font-medium leading-4">You haven’t set company Business Hours</p>
              <p class="text-gray-500 text-xs font-normal leading-4">Response times for this mailbox will be <br> calculated without using Business Hours.</p>
            </template>
          </div>
        </div>
      </td>

      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ setDates(mailbox.dataAdded) }}</td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
        <p :class="[
          'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium',
          { 'text-green-800 bg-green-100': mailbox.status == 'Active' },
          { 'text-indigo-800 bg-indigo-100': mailbox.status == 'Loading data' },
          { 'text-red-800 bg-red-100': mailbox.status == 'Error' },
        ]">
          <svg :class="[
            'mr-1 h-2 w-2',
            { 'text-green-400': mailbox.status == 'Active' },
            { 'text-indigo-400': mailbox.status == 'Loading data' },
            { 'text-red-400': mailbox.status == 'Error' },
          ]" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
          {{ mailbox.status }}
        </p>
      </td>
      <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 text-center">
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="flex items-center rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 focus:ring-offset-0">
              <span class="sr-only">Open options</span>
              <DotsHorizontalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute py-1 right-0 z-10 w-[164px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div>
                <MenuItem >
                  <ButtonR
                    type-color="gray"
                    text="Edit Business Hours"
                    @click="
                    showModalMailboxBusinessHours({
                      name: MODALS.updateBusiness,
                      data: {
                        mailbox: [mailbox],
                      }
                    })"
                  />
                </MenuItem>
                <MenuItem>
                  <ButtonR type-color="red" text="Remove mailbox" @click="removeModal(removeData([mailbox.email]))" />
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<style scoped></style>
