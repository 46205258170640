<script setup lang="ts">
import { ref } from 'vue'
import googleUrl from '@/assets/icons/google.svg'
import microsoftUrl from '@/assets/icons/microsoft.svg'
import logoUrl from '@/assets/images/logo.svg'
import error from '@/assets/icons/error.png'
import { LOGIN_G, LOGIN_M } from '@/api/url'

const hasError = ref<boolean>(Boolean(localStorage.getItem('errorLogin')))
const loginGoogle = ():void => {
  window.location.href = `${import.meta.env.VITE_BACKEND_HOST}${LOGIN_G}`
}

const loginMicrosoft = ():void => {
  window.location.href = `${import.meta.env.VITE_BACKEND_HOST}${LOGIN_M}`
}
</script>

<template>
  <div class="login-bg h-screen w-full text-center flex flex-col">
    <div class="mx-auto my-6">
      <img :src="logoUrl" width="200" />
    </div>
    <div class="relative max-w-lg w-full m-auto">
      <div class="">
        <p class="text-2xl font-semibold leading-8 text-gray-900 text-center mb-6 mt-0">Log in - Email Meter Enterprise</p>
        <div v-if="hasError" class="bg-red-100 mb-6 py-4 px-6 text-red-400 my-4 shadow-sm rounded border border-solid border-red-200 text-sm leading-8">
          <img class="mt-2 w-5 h-5 m-auto" :src="error" />
          <p class="font-medium mt-2">This account doesn’t have access to Email Meter Enterprise</p>
          <p class="m-0 font-normal leading-none">Try again, and contact us if you believe this is an error</p>
        </div>
        <div class="flex mx-auto border-0">
          <button type="button" @click="loginGoogle" class="flex justify-center shadow-sm w-full items-center rounded border border-solid border-gray-300 bg-white p-2.25 hover:bg-gray-50 focus:outline-none cursor-pointer focus:shadow-outline">
            <img :src="googleUrl" />
            <p class="text-base m-0 ml-3 font-medium leading-6 font-inter text-gray-700">Sign in with Google</p>
          </button>
        </div>

        <div class="flex mx-auto border-0 mt-3">
          <button type="button" @click="loginMicrosoft" class="flex justify-center pl-5 shadow-sm w-full items-center rounded border border-solid border-gray-300 bg-white p-2.25 hover:bg-gray-50 focus:outline-none cursor-pointer focus:shadow-outline">
            <img :src="microsoftUrl" class="w-5 h-5" />
            <p class="text-base m-0 ml-3 font-medium leading-6 font-inter text-gray-700">Sign in with Microsoft</p>
          </button>
        </div>
      </div>
    </div>
    <!-- Login bottom -->
    <div class="md:flex items-center justify-between px-8 py-6">
      <p class="text-gray-500 font-inter font-normal text-base leading-6 m-0">
        No Enterprise account?
        <a
          class="text-gray-500 hover:text-indigo-500 font-semibold ml-2 cursor-pointer no-underline"
          href="mailto:hello@emailmeter.com"
        >
          Get in touch
        </a>
      </p>
      <div class="flex text-base flex-wrap font-inter justify-center m-0">

        <a
          class=" text-gray-500 hover:text-indigo-500 leading-6 font-semibold  no-underline"
          href="https://www.emailmeter.com/privacy-policy"
          target="_blank"
        >
          Privacy
        </a>
        <a
          class="ml-4 text-gray-500 hover:text-indigo-500 leading-6 font-semibold no-underline"
          href="https://www.emailmeter.com/terms-of-service"
          target="_blank"
        >
          Terms
        </a>
        <a class="ml-4 text-gray-500 hover:text-indigo-500 leading-6 font-semibold no-underline" href="https://help.emailmeter.com/en/" target="_blank">
          Get help
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.login-bg {
  background-image: url(@/assets/images/background-down.svg), url(@/assets/images/background-up.svg);
  background-position: 0 100%, 100% 0;
  background-size: 20%, 25%;
  background-repeat: no-repeat, no-repeat;
}
</style>
