<script setup lang="ts">
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import { InformationCircleIcon } from '@heroicons/vue/outline'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import useModal from '@/modules/shared/composable/useModal'

const { userInfo } = useUserInfo()
const { hideModal } = useModal()

const getInTouch = () => { window.location = 'mailto:success@emailmeter.com' }
</script>

<template>
  <div class="p-6 overflow-auto">
    <div class="bg-orange-50 w-12 h-12 flex items-center justify-center rounded-full ">
      <InformationCircleIcon class="w-6 h-6 text-orange-500" />
    </div>

    <p class="text-gray-900 font-medium leading-6 text-lg mt-5">You’ve reached your user limit</p>
    <p class="text-sm leading-5 font-normal text-gray-500 pt-2">
      Your workspace is at its maximum limit of {{ userInfo.maxUsers }} users. Please remove<br>
      some users or get in touch with your Account Manager to increase your limit.
    </p>
    <div class="flex justify-end pt-6">
      <ButtonG class="!w-fit" type-color="bg-gray" text="Go Back" @onClick="hideModal" />
      <ButtonG class="ml-2 !w-fit" text="Get in touch" @onClick="getInTouch" />
    </div>
  </div>
</template>
