<script setup lang="ts">
import {
  useRoute, useRouter
} from 'vue-router'
import {
  ChartBarIcon, CogIcon, QuestionMarkCircleIcon, BellIcon
} from '@heroicons/vue/outline'

import clientsApi from '@/api/clients-api'
import { ALERTS_ROUTES } from '@/modules/explore/utils/constants/routes'
import { SETTINGS_ROUTES } from '@/modules/settings/utils/constants/routes'
import ArrowRightRectIcon from '@/modules/shared/components/Icons/ArrowRightRectIcon.vue'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import imgUrl from '@/assets/images/logo_white.svg'

const route = useRoute()
const router = useRouter()
const { userInfo, isLoading } = useUserInfo()

const menu = [
  { router: true, name: 'Dashboard', icon: ChartBarIcon, href: 'dashboard', current: route.name === 'dashboard' },
  { router: true, name: 'Alerts', icon: BellIcon, href: ALERTS_ROUTES.Alerts, current: route.name === 'Alerts' },
  { router: true, name: 'Settings', icon: CogIcon, href: SETTINGS_ROUTES.MailboxesSettings, current: route.matched[0].name === 'settings' },
  { router: false, name: 'Help', icon: QuestionMarkCircleIcon, href: 'https://docs.enterprise.emailmeter.com/', current: false }
]

const logout = async (): Promise<void> => {
  await clientsApi.post('/portal/expire-token/')
  localStorage.removeItem('userToken')
  router.push({ name: 'login' })
}
</script>

<template>
  <div v-if="!isLoading" class="flex w-screen h-screen">
    <div class="h-screen flex w-72 min-h-0 flex-col bg-secondary-500">
      <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
        <div class="flex flex-shrink-0 items-center px-6.5">
          <router-link :to="{ name: 'dashboard' }">
            <img :src="imgUrl" alt="Email Meter" width="150" />
          </router-link>
        </div>
        <nav class="mt-10.5 px-4" aria-label="Sidebar">
          <div class="pb-6">
            <template v-for="item in menu" :key="item.name">
              <template v-if="item.router">
                <!-- userInfo.roles !== 'admin' -->
                <template v-if="item.name !== 'Settings' || userInfo.roles === 'admin'">
                  <router-link :to="{ name: item.href }" :href="item.href" :class="[item.current ? 'bg-secondary-400 text-white  mt-1' : ' mt-1 text-gray-300 transition duration-0 hover:duration-200 hover:text-white', 'group flex items-center py-2 pl-2 pr-3 text-sm font-medium rounded-md']">
                    <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    <span class="flex-1">{{ item.name }}</span>
                    <span v-if="item.name == 'Alerts'" class="inline-flex items-center rounded-2.5 bg-secondary-300 px-3 py-0.5 text-xs font-medium text-gray-100 ring-1 ring-inset ring-gray-500/10">Beta</span>
                  </router-link>
                </template>
              </template>
              <a v-else :href="item.href" target="_blank" class="text-gray-300  hover:text-white group flex items-center py-2 pl-2 pr-3 text-sm font-medium rounded-md">
                <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                <span class="flex-1">{{ item.name }}</span>
              </a>
            </template>
          </div>
        </nav>
      </div>

      <div class="flex flex-col justify-between p-4 pb-6">
        <p class="my-3 uppercase font-semibold text-xs text-secondary-300">ACCOUNT</p>
        <div class="flex items-center justify-between">
          <div>
            <p class="my-0 text-sm font-medium leading-5 text-gray-200">{{ userInfo!.name }}</p>
            <p class="my-0 text-xs text-white leading-4 whitespace-nowrap truncate w-56">{{ userInfo!.email }}</p>
          </div>
          <div class="cursor-pointer w-5 h-5">
            <ArrowRightRectIcon class="text-gray-200 w-5 h-5" @click="logout" />
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-y-auto w-full">
      <div class="w-full h-full px-8 py-6 pb-0 max-w-[1184px] mx-auto my-0">
        <slot name="header"></slot>
        <slot name="dashboard"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
