import { watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import clientsApi from '@/api/clients-api'
import { storeToRefs } from 'pinia'
import parseUserInfo from '@/modules/shared/utils/parser/userInfo'
import type { IUserInfo } from '@/modules/shared/interfaces/UserInfo'
import { useUserInfoStore } from '@/modules/shared/stores/userInfo'
import { ME } from '@/api/url'

const getUserInfo = async ():Promise<IUserInfo> => {
  const { data } = await clientsApi.get(ME)
  return parseUserInfo(data)
}

const useUserInfo = () => {
  const store = useUserInfoStore()
  const { userInfo } = storeToRefs(store)

  const { isLoading, data } = useQuery(
    ['userInfo'],
    getUserInfo,
    { staleTime: 10 * (60 * 1000) }
  )

  watch(data, newUserInfo => {
    if (newUserInfo) store.setUserInfo(newUserInfo)
  }, { immediate: true })

  return {
    userInfo,
    isLoading
  }
}

export default useUserInfo
