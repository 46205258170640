<script setup lang="ts">
import MailboxesModal from '@/modules/settings/components/modals/MailboxesModal.vue'
import MailboxLimitReachedModal from '@/modules/settings/components/modals/MailboxLimitReachedModal.vue'
import UserLimitReachedModal from '@/modules/settings/components/modals/UserLimitReachedModal.vue'
import UserModal from '@/modules/settings/components/modals/UserModal.vue'
import TeamModal from '@/modules/settings/components/modals/TeamModal.vue'
import RemoveModal from '@/modules/shared/components/RemoveModal.vue'
import AlertsModal from '@/modules/explore/components/modal/AlertsModal.vue'
import BusinessModal from '@/modules/settings/components/modals/BusinessModal.vue'
import AddContactGroup from '@/modules/settings/components/modals/ContactGroup/AddContactGroup.vue'

import AddContact from '@/modules/settings/components/modals/ContactGroup/AddContact.vue'
import RemoveContacts from '@/modules/settings/components/modals/ContactGroup/RemoveContacts.vue'
import RenameContactGroup from '@/modules/settings/components/modals/ContactGroup/RenameContactGroup.vue'
import RemoveContactGroup from '@/modules/settings/components/modals/ContactGroup/RemoveContactGroup.vue'
import ConfirmSaveModal from '@/modules/settings/components/modals/ConfirmSaveModal.vue'

import MODALS_ENUM from '@/modules/shared/utils/modals_enum'

import { useModalStore } from '@/modules/shared/stores/modal'
import { XIcon } from '@heroicons/vue/solid'
import { storeToRefs } from 'pinia'
import { onBeforeMount, onBeforeUnmount } from 'vue'

const store = useModalStore()
const { display, hasCloseButton, activeModal } = storeToRefs(store)

const onKeyPress = (e: { key: string }) => {
  if (e.key === 'Escape') {
    store.hideModal()
  }
}

onBeforeMount(() => {
  window.addEventListener('keydown', onKeyPress)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyPress)
})
</script>

<template>
  <Transition :duration="550" name="nested">
    <div v-if="display" class="z-50 is-active h-full w-full flex absolute overflow-hidden">
      <div class="bg-[rgba(0,0,0,0.5)] h-screen w-full absolute"></div>
      <div
        class="relative flex flex-col inner modal-content bg-white my-auto shadow-lg mx-auto lg:mx-auto sm:w-11/12 lg:w-3/4 xl:w-1/2 max-w-[580px] max-h-[80%] p-0 z-10 rounded-lg"
        :class="{'!w-[512px]' : [MODALS_ENUM.userLimitReached, MODALS_ENUM.mailboxLimitReached, MODALS_ENUM.confirmSaveModal].includes(activeModal)}"
      >
        <template v-if="hasCloseButton">
          <button
            class="modal-close absolute right-0 xl:mx-6 mx-5 xl:my-7 my-5 bg-transparent border-none text-xl text-gray-700"
            aria-label="close">
            <XIcon class="w-6 h-6 text-gray-700" @click="store.hideModal" />
          </button>
        </template>

        <MailboxesModal v-if="activeModal === MODALS_ENUM.addMailboxes" />
        <MailboxLimitReachedModal v-if="activeModal === MODALS_ENUM.mailboxLimitReached" />
        <UserLimitReachedModal v-if="activeModal === MODALS_ENUM.userLimitReached" />
        <UserModal v-if="[MODALS_ENUM.addUser, MODALS_ENUM.updateUser].includes(activeModal)" />
        <TeamModal v-if="[MODALS_ENUM.addTeams, MODALS_ENUM.updateTeams].includes(activeModal)" />
        <RemoveModal v-if="activeModal === MODALS_ENUM.removeModal" />
        <AlertsModal v-if="[MODALS_ENUM.addAlerts, MODALS_ENUM.updateAlerts].includes(activeModal)" />
        <BusinessModal v-if="activeModal === MODALS_ENUM.updateBusiness" />

        <AddContactGroup v-if="activeModal === MODALS_ENUM.addContactGroup" />
        <AddContact v-if="activeModal === MODALS_ENUM.addContact" />
        <RemoveContacts v-if="activeModal === MODALS_ENUM.removeContacts" />
        <RenameContactGroup v-if="activeModal === MODALS_ENUM.renameContactGroup" />
        <RemoveContactGroup v-if="activeModal === MODALS_ENUM.removeContactGroup" />

        <ConfirmSaveModal v-if="activeModal === MODALS_ENUM.confirmSaveModal" />
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}

/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(0px);
  opacity: 0;
}

/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}

/* delay enter of nested element */
.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
</style>
