<script setup lang="ts">
import { computed } from 'vue'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline'
import help from '@/assets/icons/help.svg'
import useTable from '@/modules/settings/composables/useTable'
import useMailboxes from '@/modules/settings/composables/useMailboxes'

const props = defineProps({
  selectFiltered: {
    type: Object,
    required: true
  }
})
const { asc, typeOrder, selectedItems, selectAll, indeterminate, orderBy } = useTable()
const { mailboxes } = useMailboxes()
const all = computed(() => props.selectFiltered?.map((p: { email: string; }) => p?.email))
</script>

<template>
  <thead>
    <tr class="bg-gray-50">
      <th scope="col" class="relative px-7 sm:w-12 sm:px-6 rounded-t-md">
        <input
          id="check_first"
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500 focus:ring-0 focus:ring-offset-0"
          :checked="indeterminate(mailboxes.length) || selectedItems.length === mailboxes.length"
          :indeterminate="indeterminate(mailboxes.length)"
          @change="selectAll($event, all)"
        />
      </th>
      <th scope="col" class="w-3/5 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900" @click="orderBy('email')">
        <div class="flex items-center cursor-pointer group">
          Mailbox
          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'email' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>
      <th scope="col" class=" lg:w-1/5 xl:1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-700"
          @click="orderBy('bhsStatus')">
        <div class="flex items-center cursor-pointer group">
          Business Hours
          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'bhsStatus' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>
      <th scope="col" class="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-700"
          @click="orderBy('dataAdded')">
        <div class="flex items-center cursor-pointer group">
          Date added
          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'dataAdded' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>

      <th scope="col" class="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900" @click="orderBy('status')">
        <div class="flex items-center relative w-max cursor-pointer group">
          Status
          <div class="group flex">
            <img :src="help" alt="help" class="w-4 ml-2 text-gray-400" />
            <span
              class="drop-shadow-tool bg-secondary-500 text-white font-medium text-xs w-73 invisible transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100 group-hover:visible absolute px-3 py-2 -right-0.5 -top-8 rounded-lg">
              Shows if the mailbox is currently being tracked
            </span>
          </div>
          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'status' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>
      <th scope="col" class="w-1/12 relative py-3.5 pl-3 pr-4 sm:pr-3 rounded-r-md text">
        <span class="sr-only">Edit</span>
      </th>
    </tr>
  </thead>
</template>

<style scoped></style>
